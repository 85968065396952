import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import CustomText from "../lib/CustomText";
import lockIcon from "../../assets/langing-page/An-icon-of-a-secure-lock-with-a-checkmark-representing-data-security-alongside-a-brief-explanation.png";
import form from "../../assets/langing-page/A-feedback-form-turning-into-a-feature-update.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import liveIcon from "../../assets/langing-page/live-icon.png";
import { AppColours } from "../../helpers/colors";
import locationImage from "../../assets/langing-page/location.png";
import todoImage from "../../assets/langing-page/todo.png";
import clockImage from "../../assets/langing-page/clock.png";

interface cardProp {
  head: string;
  body: string;
  image: string;
}

const cardData: cardProp[] = [
  {
    head: "Real-time Walk-in & Online Booking - Manage both walk-in and online bookings live",
    body: "Accept walk-in appointments and seamlessly use our online booking system to streamline your workflow.",
    image: locationImage,
  },
  {
    head: "Effortless Online Booking - Reduce phone calls and wasted time.",
    body: " Allow clients to book appointments 24/7 through a user-friendly online booking system.",
    image: clockImage,
  },
  {
    head: "Automated Reminders & Confirmations - Reduce no-shows and improve client communication.",
    body: "Set up automated appointment confirmations, reminders, and follow-ups.",
    image: todoImage,
  },
];

export default function UniqueShareduled() {
  return (
    <Container
      maxWidth="lg"
      id="unique-shareduled-section"
      sx={{
        marginTop: "12rem",
        minHeight: "inherit",
        justifyContent: "flex-start",
        alignItems: "center",
        pb: { xs: 8, sm: 10 },
      }}
    >
      <Stack
        spacing={{ xs: 2, sm: 3 }}
        useFlexGap
        sx={{
          height: "inherit",
          width: { xs: "100%", sm: "100%" },
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            flexWrap: "wrap",
            textAlign: "center",
            fontWeight: "600",
            color: (theme) => theme?.palette?.primary?.main,
          }}
        >
          <Typography
            variant="h4"
            component="span"
            sx={{ fontStyle: "italic" }}
          >
            Shareduled
          </Typography>{" "}
          Is Unlike Any App You've Ever Used Before… (And Way Better Too)
        </Typography>

        {/* Grid and Card for Credibility */}

        <Grid container spacing={12} marginBottom="6rem" marginTop={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                width: "100%",
                boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
                borderRadius: "20px",
              }}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  sx={{
                    height: {
                      xs: 140, // height for extra-small screens (mobile)
                      sm: 180, // height for small screens (tablet)
                      md: 200, // height for medium screens (desktop)
                    },
                    objectFit: "cover",
                  }}
                  image={liveIcon}
                  alt="Live location"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="body1"
                    component="div"
                    sx={{
                      lineHeight: "26px",
                      textAlign: "left",
                      fontWeight: "300",
                      color: AppColours.MainBlack,
                      pb: { xs: 2, sm: 1, md: 1 },
                    }}
                  >
                    Real-time Walk-in & Online Booking - Manage both walk-in and
                    online bookings live
                  </Typography>
                  <Typography variant="body2" style={{ fontWeight: "200" }}>
                    Accept walk-in appointments and seamlessly use our online
                    booking system to streamline your workflow.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          {cardData.map(({ head, body, image }) => (
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  width: "100%",
                  boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
                  borderRadius: "20px",
                  paddingX: "1rem",
                  paddingY: "1rem",
                }}
              >
                <CardActionArea>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center", // Centers the image horizontally
                      alignItems: "center", // Centers the image vertically
                      overflow: "hidden", // Ensures no overflow from the image
                      borderRadius: "50%", // Make the container round
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        width: "70%", // Make the image fill the container width
                        height: "70%", // Make the image fill the container height
                        objectFit: "cover", // Ensure the image covers the container without distortion
                        borderRadius: "50%", // Keep the image round
                        paddingY: "3rem",
                      }}
                      image={image}
                      alt="Live location"
                    />
                  </Box>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="body1"
                      component="div"
                      sx={{
                        lineHeight: "26px",
                        textAlign: "center",
                        fontWeight: "300",
                        color: AppColours.MainBlack,
                        pb: { xs: 2, sm: 1, md: 1 },
                      }}
                    >
                      {head}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "200", textAlign: "center" }}
                    >
                      {body}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>

        <CustomText textPlain={`Hey, Joseph here…`} />
        <CustomText
          textPlain={`If you're reading this, it means you need a better appointment booking system.`}
        />
        <CustomText
          textPlain={`Well, creating software that makes life easy for businesses like yours is what I do.`}
        />
        <CustomText
          textPlain={`I know a lot of businesses who lose a lot of money preparing for clients that never show up…`}
        />
        <CustomText
          textPlain={`I myself have been frustrated by spending hours upon hours online… looking for the perfect massage therapist who is FREE.`}
        />
        <CustomText
          textPlain={`I understand both sides of the spectrum: the business owner and the client in dire need for a treat…`}
        />
        <CustomText textPlain={`I've been there. `} />

        <Typography
          variant="body2"
          sx={{
            fontWeight: "300",
            alignSelf: "flex-start",
          }}
        >
          This made me gather my 5+ years of experience creating powerful
          software for national brands to create{" "}
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontWeight: "600",
              alignSelf: "flex-start",
            }}
          >
            Shareduled
          </Typography>{" "}
          for you.
        </Typography>

        <CustomText textPlain={`As you can tell by now…`} />
        <CustomText textPlain={`It's unlike any other app out there.`} />
        <CustomText
          textPlain={`It was designed not just for any service-based business…`}
        />

        <Typography
          variant="body2"
          sx={{
            fontWeight: "300",
            alignSelf: "flex-start",
          }}
        >
          But{" "}
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontWeight: "300",
              alignSelf: "flex-start",
              textDecoration: "underline",
            }}
          >
            specifically
          </Typography>{" "}
          for the owners of barbershops, massage houses, spa and salons.
        </Typography>

        <Grid container spacing={12} marginTop={5}>
          <Grid item xs={12} sm={12} md={4}>
            <Card
              sx={{
                width: "100%",
                boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
                borderRadius: "20px",
              }}
            >
              <CardActionArea>
                {/* head */}
                <Typography
                  variant="h5"
                  component="h2"
                  textAlign="center"
                  sx={{ fontWeight: "600", color: AppColours.MainBlack }}
                  marginY={2}
                >
                  A Vision for Seamless Scheduling
                </Typography>

                <CardMedia
                  component="video"
                  sx={{
                    objectFit: "cover",
                  }}
                  src="/v2.mp4"
                  controls
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="body1"
                    component="div"
                    sx={{
                      lineHeight: "26px",
                      textAlign: "left",
                      fontWeight: "300",
                      color: AppColours.MainBlack,
                      pb: { xs: 2, sm: 1, md: 1 },
                    }}
                  >
                    How we started. The first “rough” sketches in motion
                  </Typography>

                  <Stack spacing={2}>
                    <Typography variant="body2" style={{ fontWeight: "200" }}>
                      At the heart of Shareduled is a vision for a world where
                      scheduling is no longer a hurdle but a stepping stone to
                      success. Our relentless pursuit of this vision has shaped
                      every aspect of Shareduled.
                    </Typography>

                    <Typography variant="body2" style={{ fontWeight: "200" }}>
                      Think real-time, live updates and live changes. Think
                      Shareduled.
                    </Typography>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Card
              sx={{
                width: "100%",
                boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
                borderRadius: "20px",
              }}
            >
              <CardActionArea>
                {/* head */}
                <Typography
                  variant="h5"
                  component="h2"
                  textAlign="center"
                  sx={{ fontWeight: "600", color: AppColours.MainBlack }}
                  marginY={2}
                >
                  Your Data, Your Security: How Shareduled Prioritizes Privacy
                  Content
                </Typography>

                <CardMedia
                  component="img"
                  sx={{
                    height: {
                      xs: 140, // height for extra-small screens (mobile)
                      sm: 180, // height for small screens (tablet)
                      md: 200, // height for medium screens (desktop)
                    },
                    objectFit: "cover",
                  }}
                  image={lockIcon}
                  alt="Secure lock icon"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="body1"
                    component="div"
                    sx={{
                      lineHeight: "26px",
                      textAlign: "left",
                      fontWeight: "300",
                      color: AppColours.MainBlack,
                      pb: { xs: 2, sm: 1, md: 1 },
                    }}
                  >
                    We understand the importance of protecting your client data.
                    Shareduled prioritizes security and privacy with
                    industry-leading practices
                  </Typography>

                  <Box>
                    <Box marginY={3} sx={{ display: "flex", gap: "13px" }}>
                      <CheckCircleIcon
                        sx={{
                          color: "#550066",
                        }}
                      />
                      <Typography variant="body2" style={{ fontWeight: "200" }}>
                        256-bit encryption safeguards your data both in transit
                        and at rest.
                      </Typography>
                    </Box>

                    <Box marginY={3} sx={{ display: "flex", gap: "13px" }}>
                      <CheckCircleIcon
                        sx={{
                          color: "#550066",
                        }}
                      />
                      <Typography variant="body2" style={{ fontWeight: "200" }}>
                        Secure cloud storage with trusted providers ensures data
                        accessibility and protection
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", gap: "13px" }}>
                      <CheckCircleIcon
                        sx={{
                          color: "#550066",
                        }}
                      />
                      <Typography variant="body2" style={{ fontWeight: "200" }}>
                        Regular backups guarantee information recovery in case
                        of unforeseen circumstances.
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Card
              sx={{
                width: "100%",
                boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
                borderRadius: "20px",
              }}
              elevation={2}
            >
              <CardActionArea>
                {/* head */}
                <Typography
                  variant="h5"
                  component="h2"
                  textAlign="center"
                  sx={{ fontWeight: "600", color: AppColours.MainBlack }}
                  marginY={2}
                >
                  Listening Today, Improving Tomorrow
                </Typography>
                <CardMedia
                  component="img"
                  sx={{
                    height: {
                      xs: 140, // height for extra-small screens (mobile)
                      sm: 180, // height for small screens (tablet)
                      md: 200, // height for medium screens (desktop)
                    },
                    objectFit: "cover",
                  }}
                  image={form}
                  alt="digital form image"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="body1"
                    component="div"
                    sx={{
                      lineHeight: "26px",
                      textAlign: "left",
                      fontWeight: "300",
                      color: AppColours.MainBlack,
                      pb: { xs: 2, sm: 1, md: 1 },
                    }}
                  >
                    Turn your feedback to features that matter
                  </Typography>
                  <Typography variant="body2" style={{ fontWeight: "200" }}>
                    Your feedback is the compass that guides our service
                    improvements. Shareduled listens to your needs and
                    continuously evolves to serve you better.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>

        <CustomText textPlain={`And this is because…`} />
      </Stack>
    </Container>
  );
}
