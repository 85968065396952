import {
  Alert,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { AppColours } from "../../helpers/colors";
import {
  formatPhoneNumber,
  officialCountriesArray,
} from "../../helpers/formulae";
import { AgeRangeType, ShareduledUser } from "../screens/LeadMagnetScreen";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  MarketingUserDtoAgeRangeEnum,
  MarketingUserDtoUserPathEnum,
} from "../../sdk/open-api";
import { createMarketingUser } from "../../services/apis/user.service";
import {
  useMemoizedVerifyUserEmail,
  useMemoizedVerifyPhoneNumber,
} from "../../hooks/use-callback-custom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function LeadMagnetJoinForm(
  props: ShareduledUser & {
    setUser: React.Dispatch<React.SetStateAction<ShareduledUser>>;
  },
) {
  const navigate = useNavigate();

  const {
    firstName,
    lastName,
    country,
    countryShortCode,
    phoneNumber,
    phoneNumberCountryShortCode,
    businessName,
    businessEmail,
    ageRange,
    phoneNumberValid,
    businessEmailValid,
    setUser,
  } = props;

  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const verifyUserEmail = useMemoizedVerifyUserEmail(businessEmail, setUser);
  const verifyUserPhoneNumber = useMemoizedVerifyPhoneNumber(
    phoneNumber,
    setUser,
  );

  const handleGiveMeVIPAcess = async () => {
    try {
      setLoading(true);

      const created = await createMarketingUser({
        first_name: firstName,
        last_name: lastName,
        business_name: businessName,
        business_email: businessEmail,
        country,
        phone_number: formatPhoneNumber(phoneNumber),
        age_range: ageRange as unknown as MarketingUserDtoAgeRangeEnum,
        user_path: MarketingUserDtoUserPathEnum.Waitlist,
      });

      if (!created) throw new Error("Could not create user");
      if (!created.data)
        throw new Error(created?.message ?? "No data returned");

      navigate("/our-gift", {
        state: {
          user: created.data,
          existing: created.existing ?? false,
        },
      });

      setLoading(false);
    } catch (e: any) {
      console.warn("HDPP Error: ", e);
      setErrMsg(
        typeof e === "string" ? e : (e.message ?? "Something went wrong"),
      );
      setLoading(false);
    }
  };

  const handleBlur = async (
    _e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => {
    if (phoneNumber) {
      await verifyUserPhoneNumber();
    } else {
      console.log("Cannot verify invalid phone number");
    }

    if (businessEmail) {
      await verifyUserEmail();
    } else {
      console.warn("Cannot verify invalid business email");
    }
  };

  return (
    <Container
      maxWidth="lg"
      id="lead-magnet-life-time-access-form-section"
      sx={{
        minHeight: "inherit",
        justifyContent: "center",
        alignItems: "center",
        pt: { xs: 3, sm: 12 },
        pb: { xs: 6, sm: 6 },
      }}
    >
      <Grid
        container
        spacing={{ xs: 4, sm: 8 }}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "inherit",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            height: "inherit",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            spacing={{ xs: 2, sm: 3 }}
            useFlexGap
            sx={{
              height: "inherit",
              width: { xs: "100%", sm: "100%" },
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              pt: { xs: 10, sm: 10 },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                display: "flex",
                alignSelf: "center",
                flexWrap: "wrap",
                textAlign: "left",
                fontWeight: "bold",
                color: (theme) => theme?.palette?.primary.main,
              }}
            >
              Get VIP Access To Intelligent Scheduling and Real-Time Bookings So
              You Can Take Back Your Time
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontWeight: "300",
              }}
            >
              Hey, wellness rockstar and amazing fan. We love you too. Thanks
              for coming to see how you, our biggest fan, can get better profit
              potential in your business.
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontWeight: "300",
              }}
            >
              You can see how Shareduled's Smart Scheduling can help you make
              massive leap in your revenue whether you are a:
            </Typography>

            <List sx={{ mb: { xs: 4, sm: 5, md: 6 } }}>
              {[
                "barber keeping your clients looking sharp",
                "massage therapist helping folks unwind",
                "salon working your magic",
                "massage shop and spa getting body muscles relaxed and making people happier",
              ].map((x, index) => (
                <ListItem disablePadding key={index.toString()}>
                  <ListItemIcon>
                    <CheckCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    <Typography variant="body1" style={{ fontWeight: "300" }}>
                      {x}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            height: "inherit",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            spacing={{ xs: 2, sm: 2 }}
            useFlexGap
            sx={{
              height: "inherit",
              width: { xs: "100%", sm: "100%" },
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              pt: { xs: 2, sm: 3 },
              pb: { xs: 3, sm: 3 },
            }}
          >
            {errMsg && (
              <Alert
                variant="filled"
                severity="error"
                color="error"
                onClose={() => setErrMsg("")}
                sx={{
                  alignSelf: "center",
                  mb: { xs: 1, sm: 2 },
                  width: "100%",
                  borderRadius: 4,
                }}
              >
                {errMsg}
              </Alert>
            )}

            <Typography
              variant="h4"
              sx={{
                display: "flex",
                alignSelf: "center",
                flexWrap: "wrap",
                textAlign: "left",
                fontWeight: "500",
                color: (theme) => theme?.palette?.primary.main,
              }}
            >
              Get Priority Access and VIP Perks To Shareduled Today
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontWeight: "300",
              }}
            >
              See how to get lifetime access today. See real numbers on the
              major business impact and difference we make. Don't miss out on
              the exclusive benefits and lifetime access to premium features,
              even when others are paying for our monthly plans when we open
              officially.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontWeight: "500",
              }}
            >
              Please, give us your correct details to see how to get your VIP
              perks. It takes less than a minute.
            </Typography>

            <Grid
              container
              spacing={{ xs: 2, sm: 3 }}
              sx={{ mt: { xs: 1, sm: 2 } }}
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="firstName"
                  name="firstName"
                  label="First name"
                  fullWidth
                  autoComplete="given-name"
                  variant="standard"
                  value={firstName}
                  helperText={!firstName ? "Please enter your first name" : ""}
                  onChange={(event) => {
                    setUser((prev) => {
                      return { ...prev, firstName: event.target.value };
                    });
                  }}
                  onBlur={handleBlur}
                  error={!firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="lastName"
                  name="lastName"
                  label="Last name"
                  fullWidth
                  autoComplete="family-name"
                  variant="standard"
                  helperText="(Optional)"
                  value={lastName}
                  onChange={(event) => {
                    setUser((prev) => {
                      return { ...prev, lastName: event.target.value };
                    });
                  }}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="country"
                  name="country"
                  label="Country"
                  fullWidth
                  select
                  autoComplete="country"
                  variant="standard"
                  // defaultValue={country ? country : "Canada"}
                  value={country}
                  helperText={
                    !country ||
                    !countryShortCode ||
                    countryShortCode !== phoneNumberCountryShortCode
                      ? "Country and phone number country should match"
                      : ""
                  }
                  onChange={(event) => {
                    const myCountry = officialCountriesArray.find(
                      (x) => x.name === event.target.value,
                    );

                    if (myCountry) {
                      setUser((prev) => {
                        return {
                          ...prev,
                          country: myCountry.name,
                          countryShortCode: myCountry.shortCode,
                        };
                      });
                    } else {
                      console.error(
                        `Could not find country ${event.target.value} in country list`,
                      );
                    }
                  }}
                  onBlur={handleBlur}
                  error={
                    !country ||
                    !countryShortCode ||
                    countryShortCode !== phoneNumberCountryShortCode
                  }
                >
                  {officialCountriesArray.map((country) => (
                    <MenuItem key={country.shortCode} value={country.name}>
                      {country.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {
                <Grid item xs={12} sm={6}>
                  {countryShortCode && (
                    <MuiTelInput
                      required
                      id="phone-number"
                      name="phone number"
                      label="Phone Number"
                      fullWidth
                      variant="standard"
                      defaultCountry={"CA"}
                      focusOnSelectCountry
                      error={
                        !phoneNumber ||
                        !phoneNumberCountryShortCode ||
                        countryShortCode !== phoneNumberCountryShortCode ||
                        !phoneNumberValid
                      }
                      value={phoneNumber}
                      onChange={(...event) => {
                        const [telNo, details] = event as unknown as [
                          string,
                          {
                            countryCallingCode: string;
                            countryCode: string;
                            nationalNumber: string;
                            numberType: string | null;
                            numberValue: string;
                            reason: string;
                          },
                        ];

                        // const formattedTelNo = formatPhoneNumber(telNo);

                        setUser((prev) => {
                          return {
                            ...prev,
                            phoneNumber: telNo,
                            phoneNumberCountryShortCode:
                              details?.countryCode?.toLowerCase(),
                          };
                        });
                      }}
                      onBlur={handleBlur}
                      helperText={
                        !phoneNumber
                          ? "Phone number is required"
                          : !phoneNumberValid
                            ? `${phoneNumber} may not be valid`
                            : ""
                      }
                    />
                  )}
                </Grid>
              }
              <Grid item xs={12}>
                <TextField
                  id="businessname"
                  name="businessname"
                  label="Business name"
                  fullWidth
                  autoComplete="business-name"
                  variant="standard"
                  helperText="(Optional)"
                  value={businessName}
                  onChange={(event) => {
                    setUser((prev) => {
                      return { ...prev, businessName: event.target.value };
                    });
                  }}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id="businessemail"
                  name="businessemail"
                  label="Business email"
                  type="email"
                  fullWidth
                  autoComplete="business-email"
                  variant="standard"
                  helperText={
                    !businessEmail
                      ? "Email is required"
                      : !businessEmailValid
                        ? `${businessEmail} may not be valid`
                        : ""
                  }
                  value={businessEmail}
                  onChange={(event) => {
                    setUser((prev) => {
                      return { ...prev, businessEmail: event.target.value };
                    });
                  }}
                  onBlur={handleBlur}
                  error={!businessEmail || !businessEmailValid}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Age Range
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={ageRange}
                    onChange={(event) => {
                      setUser((prev) => {
                        return {
                          ...prev,
                          ageRange:
                            event?.target?.value === "18_and_above"
                              ? AgeRangeType["18_and_above"]
                              : event?.target?.value === "below_18"
                                ? AgeRangeType.below_18
                                : null,
                        };
                      });
                    }}
                  >
                    <FormControlLabel
                      value="18_and_above"
                      control={<Radio />}
                      label="18 and Above"
                    />
                    <FormControlLabel
                      value="below_18"
                      control={<Radio />}
                      label="Below 18"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Typography
              variant="caption"
              sx={{
                fontWeight: "300",
              }}
            >
              By filling and submitting this form,{" "}
              <strong>
                you agree to our Terms and Conditions, Data Collection and
                Privacy, and Pricing Policies
              </strong>
              &nbsp; (See Bottom of Page)
            </Typography>

            {loading ? (
              <CircularProgress
                id="lead-magnet-home-spinner"
                color="primary"
                sx={{ alignSelf: "center", mt: { xs: 3, sm: 4 } }}
              />
            ) : (
              <Button
                color="primary"
                variant="contained"
                sx={{
                  mt: { xs: 2, sm: 3 },
                  alignSelf: "center",
                  borderRadius: "8px",
                  textTransform: "none",
                  backgroundColor: AppColours.MainRed,
                  padding: "12px",
                  width: "100%",
                }}
                onClick={handleGiveMeVIPAcess}
                disabled={
                  !firstName ||
                  !country ||
                  !phoneNumber ||
                  !phoneNumberValid ||
                  !businessEmail ||
                  !businessEmailValid ||
                  !ageRange ||
                  ageRange !== AgeRangeType["18_and_above"] ||
                  countryShortCode !== phoneNumberCountryShortCode
                }
              >
                Give Me My VIP Perks and Priority Access
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
