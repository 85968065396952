import { Configuration, PublicApi } from "../../sdk/open-api";

export interface IResponse {
  success: boolean;
  message?: string;
}

export interface ICreatePaymentIntentResponse extends IResponse {
  clientSecret?: string;
}

const config = new Configuration();

export const publicApiClient = new PublicApi(
  config,
  process.env.REACT_APP_BE_BASE_URL!,
);
