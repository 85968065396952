import "tailwindcss/tailwind.css"; // Ensure Tailwind is loaded after Material UI

import React from "react";
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material";
import ROICalculatorScreen from "./components/screens/ROICalculatorScreen";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LifetimeAccessScreen from "./components/screens/LifetimeAccessScreen";
import LeadMagnetScreen from "./components/screens/LeadMagnetScreen";
import LeadMagnetPaymentScreen from "./components/screens/LeadMagnetPaymentScreen";
import PaymentSuccessScreen from "./components/screens/PaymentSuccessScreen";
import ComingSoonScreen from "./components/screens/ComingSoonScreen";
import LeadMagnetJoinFormScreen from "./components/screens/LeadMagnetJoinFormScreen";
import OurGiftScreen from "./components/screens/OurGiftScreen";
// import LifeAccessScreen from './components/screens/LifetimeAccessScreen';
// import LandingPageScreen from './components/screens/LandingPageScreen';
import NewLandingPage from "./components/screens/NewLandingPage";
import Blog from "./components/screens/Blog";
import ArticleDetails from "./components/blog/ArticleDetails";

const router = createBrowserRouter([
  {
    path: "/",
    element: <NewLandingPage />,
    // Redirecting all users to homepage for now as it is under construction
    errorElement: <NewLandingPage />,
  },
  {
    path: "/home",
    element: <NewLandingPage />,
    // Redirecting all users to homepage for now as it is under construction
    errorElement: <NewLandingPage />,
  },
  {
    path: "/lifetime-access",
    element: <LifetimeAccessScreen />,
  },
  {
    path: "/welcome",
    element: <LeadMagnetScreen />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/blog/:title",
    element: <ArticleDetails />,
  },
  {
    path: "/roi-calculator",
    element: <ROICalculatorScreen />,
    errorElement: <LeadMagnetScreen />,
  },
  {
    path: "/join-for-life",
    element: <LeadMagnetPaymentScreen />,
  },
  {
    path: "/payment-success",
    element: <PaymentSuccessScreen />,
  },
  {
    path: "/join-coming-soon",
    element: <ComingSoonScreen />,
  },
  {
    path: "/life-time-access-form",
    element: <LeadMagnetJoinFormScreen />,
  },
  {
    path: "/our-gift",
    element: <OurGiftScreen />,
  },
]);

let theme = createTheme({
  typography: {
    fontFamily: ["Kanit"].join(","),
    fontSize: 16,
  },
  palette: {
    primary: {
      main: "#550066",
    },
    secondary: {
      main: "#FFA000",
      light: "#FFD080",
    },
    info: {
      main: "#0B0D0F",
    },
    success: {
      main: "#009900",
    },
    error: {
      main: "#FF0000",
    },
    warning: {
      main: "#FFFF00",
    },
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
