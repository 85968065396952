import { Box, Link, Stack, Typography } from "@mui/material";

const Features = () => {
  return (
    <Box
      component="section"
      marginTop={8}
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <Stack spacing={4} margin="auto">
        <Typography
          component="h2"
          variant="h4"
          sx={{
            color: "#550066",
            fontWeight: "bold",
            marginY: "1rem",
            textAlign: "center",
          }}
        >
          Features
        </Typography>

        <Link
          href="/"
          underline="none"
          variant="body1"
          sx={{
            background: "#FE0000",
            textAlign: "center",
            color: "white",
            padding: "9px 20px",
            borderRadius: "15px",
          }}
        >
           5 Steps To Create A Shareduled Account For Business Owners
        </Link>

        <Link
          href="/"
          underline="none"
          variant="body1"
          sx={{
            background: "#FE0000",
            textAlign: "center",
            color: "white",
            padding: "9px 20px",
            borderRadius: "15px",
          }}
        >
           4 Steps To Create A Shareduled Account For Business Owners
        </Link>

        <Link
          href="/"
          underline="none"
          variant="body1"
          sx={{
            background: "#FE0000",
            textAlign: "center",
            color: "white",
            padding: "9px 0",
            borderRadius: "15px",
          }}
        >
           3 Steps To Create A Shareduled Account For Users
        </Link>
      </Stack>
    </Box>
  );
};

export default Features;
