import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";

export enum SubscriptionTypeEnum {
  LIFETIME = "Lifetime",
  MONTHLY = "Monthly",
  YEARLY = "Yearly",
}

type SubscriptionApiResp = {
  link: string;
  success: boolean;
  message: string;
};

function Pricing() {
  const [loading, setLoading] = useState(false);

  const [disabled, setDisabled] = useState({
    lifetimeButton: false,
    yearlyButton: true,
    monthlyButton: true,
  });

  const [selectedSubscription, setSelectedSubscription] = useState(
    SubscriptionTypeEnum.LIFETIME,
  );

  const onCardSelectLifetime = () => {
    setDisabled((_prev) => {
      return {
        lifetimeButton: false,
        monthlyButton: true,
        yearlyButton: true,
      };
    });
    setSelectedSubscription((_) => SubscriptionTypeEnum.LIFETIME);
  };

  const onCardSelectYearly = () => {
    setDisabled((_prev) => {
      return {
        lifetimeButton: true,
        monthlyButton: true,
        yearlyButton: false,
      };
    });
    setSelectedSubscription((_) => SubscriptionTypeEnum.YEARLY);
  };

  const onCardSelectMonthly = () => {
    setDisabled((_prev) => {
      return {
        lifetimeButton: true,
        monthlyButton: false,
        yearlyButton: true,
      };
    });
    setSelectedSubscription((_) => SubscriptionTypeEnum.MONTHLY);
  };

  const onButtonClick = async () => {
    try {
      setLoading(true);

      const res = (
        await axios.post(
          `${process.env.REACT_APP_BE_BASE_URL!}/public/get-subscription-link`,
          {
            businessOwnerEmail: "joseph.obiagba+002@gmail.com",
            country: "Canada",
            subscriptionType: selectedSubscription,
          },
        )
      ).data as SubscriptionApiResp;

      window.open(res.link!, "_blank");
    } catch (e) {
      console.error("onButtonClick ERROR: ", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box component="section" marginTop={5}>
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: "#550066",
          fontWeight: "bold",
          marginY: "1rem",
          textAlign: "center",
        }}
      >
        Our Prices
      </Typography>

      <Grid
        container
        spacing={3}
        justifyContent={"center"}
        alignItems={"stretch"}
      >
        <Grid item xs={12} md={4} sx={{ p: 2 }}>
          <Card sx={{ p: 2, borderRadius: 4 }}>
            <CardActionArea onClick={onCardSelectLifetime} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h5">LIFETIME ACCESS</Typography>

                <Typography>
                  Skip the free trial! Get all Shareduled exising and upcoming
                  features for LIFE! You pay once and you own your own
                  Shareduled app forever!
                </Typography>
              </CardContent>
            </CardActionArea>

            <Button
              variant="contained"
              fullWidth
              disabled={disabled.lifetimeButton || loading}
              onClick={onButtonClick}
            >
              Buy Now
            </Button>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} sx={{ p: 2 }}>
          <Card sx={{ p: 2, borderRadius: 4 }}>
            <CardActionArea onClick={onCardSelectYearly} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h5">YEARLY ACCESS</Typography>

                <Typography>
                  Get all Shareduled exising and upcoming features for a year!
                  Subscription renews automatically after you have tried
                  Shareduled free for 7 days
                </Typography>
              </CardContent>
            </CardActionArea>

            <Button
              variant="contained"
              fullWidth
              disabled={disabled.yearlyButton || loading}
              onClick={onButtonClick}
            >
              Start Trial
            </Button>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} sx={{ p: 2 }}>
          <Card sx={{ p: 2, borderRadius: 4 }}>
            <CardActionArea onClick={onCardSelectMonthly} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h5">MONTHLY ACCESS</Typography>

                <Typography>
                  Get all Shareduled exising and upcoming features for a month!
                  Subscription renews automatically after you have tried
                  Shareduled free for 7 days
                </Typography>
              </CardContent>
            </CardActionArea>

            <Button
              variant="contained"
              fullWidth
              disabled={disabled.monthlyButton || loading}
              onClick={onButtonClick}
            >
              Start Trial
            </Button>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Pricing;
