import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { AppColours } from "../../helpers/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { scrollToSection } from "../../helpers/miscellaneous";
import CustomText from "../lib/CustomText";
import zippa from "../../assets/langing-page/Zippia-Research.png";
import lockIcon from "../../assets/langing-page/An-icon-of-a-secure-lock-with-a-checkmark-representing-data-security-alongside-a-brief-explanation.png";

export default function ServiceExcellence() {
  return (
    <Container
      maxWidth="lg"
      id="service-excellence-section"
      sx={{
        marginTop: "12rem",
        minHeight: "inherit",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        pb: { xs: 8, sm: 10 },
      }}
    >
      <Stack
        spacing={{ xs: 2, sm: 3 }}
        useFlexGap
        sx={{
          height: "inherit",
          width: { xs: "100%", sm: "100%" },
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          pb: { xs: 4, sm: 6 },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            flexWrap: "wrap",
            textAlign: "center",
            fontWeight: "600",
            color: (theme) => theme?.palette?.primary?.main,
          }}
        >
          Average Business Owners Of Barbershops, Massage Houses, Spa And Salons{" "}
          <Typography
            variant="h4"
            component="span"
            sx={{
              flexWrap: "wrap",
              textAlign: "center",
              fontWeight: "600",
              color: (theme) => theme?.palette?.primary?.main,
              textDecoration: "underline",
            }}
          >
            Lose Over $10,946 Worth
          </Typography>{" "}
          Of Customers Every Year…
        </Typography>

        <Typography
          variant="h4"
          sx={{
            flexWrap: "wrap",
            textAlign: "center",
            fontWeight: "600",
            marginBottom: "6rem",
            color: (theme) => theme?.palette?.primary?.main,
          }}
        >
          Don't Let It Happen To You.
        </Typography>

        <CustomText textPlain={`Listen, this is not a bluff.`} />
        <CustomText
          textPlain={`Businesses like yours are estimated to lose over 21% of their customers every year.`}
        />
        <CustomText
          textPlain={`This translates to $10,946 assuming you average $52,123/year.`}
        />
        <CustomText textPlain={`Why?`} />
        <CustomText
          textPlain={`They lack a dialed-in system that effortlessly handles appointments bookings.`}
        />
        <CustomText
          textPlain={`The truth is, you don't have to be among those who are losing money every single day.`}
        />

        <Typography
          variant="body2"
          sx={{
            fontWeight: "300",
            alignSelf: "flex-start",
          }}
        >
          With{" "}
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontWeight: "600",
              alignSelf: "flex-start",
            }}
          >
            Shareduled
          </Typography>{" "}
          you get to be among the lucky few who are saved.
        </Typography>
      </Stack>

      <Grid container spacing={16} marginTop="1rem">
        {/* First Grid Item */}
        <Grid item xs={12} sm={6} md={6}>
          <Card
            sx={{
              width: "100%",
              boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
              borderRadius: "20px",
            }}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                height="140"
                image={zippa}
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  According to Zippia Research
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  Shareduled can help businesses save up to 10 hours per week
                  through automation and streamline operations, leading to a
                  potential{" "}
                  <Box component="span" sx={{ color: "red" }}>
                    {" "}
                    yearly 20% increase in revenue
                  </Box>
                  .
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        {/* Second Grid Item */}
        <Grid item xs={12} sm={6} md={6}>
          <Card
            sx={{
              width: "100%",
              boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
              borderRadius: "20px",
            }}
          >
            <CardActionArea>
              {/* Heading */}
              <Typography
                variant="h5"
                component="h2"
                textAlign="center"
                sx={{
                  fontWeight: "600",
                  color: AppColours.MainBlack,
                }}
                marginY={2}
              >
                Your Data, Your Security: How Shareduled Prioritizes Privacy
                Content
              </Typography>

              {/* Image */}
              <CardMedia
                component="img"
                sx={{
                  height: {
                    xs: 140, // height for extra-small screens (mobile)
                    sm: 180, // height for small screens (tablet)
                    md: 200, // height for medium screens (desktop)
                  },
                  objectFit: "cover",
                }}
                image={lockIcon}
                alt="Secure lock icon"
              />

              {/* Content */}
              <CardContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  sx={{
                    lineHeight: "26px",
                    textAlign: "left",
                    fontWeight: "300",
                    color: AppColours.MainBlack,
                    pb: { xs: 2, sm: 1, md: 1 },
                  }}
                >
                  We understand the importance of protecting your client data.{" "}
                  <Box component="span" sx={{ color: "red" }}>
                    Shareduled prioritizes security and privacy with
                    industry-leading practices
                  </Box>
                  .
                </Typography>

                {/* Bullet Points */}
                <Box>
                  <Box marginY={3} sx={{ display: "flex", gap: "13px" }}>
                    <CheckCircleIcon
                      sx={{
                        color: "#550066",
                      }}
                    />
                    <Typography variant="body2" style={{ fontWeight: "200" }}>
                      256-bit encryption safeguards your data both in transit
                      and at rest.
                    </Typography>
                  </Box>

                  <Box marginY={3} sx={{ display: "flex", gap: "13px" }}>
                    <CheckCircleIcon
                      sx={{
                        color: "#550066",
                      }}
                    />
                    <Typography variant="body2" style={{ fontWeight: "200" }}>
                      Secure cloud storage with trusted providers ensures data
                      accessibility and protection.
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", gap: "13px" }}>
                    <CheckCircleIcon
                      sx={{
                        color: "#550066",
                      }}
                    />
                    <Typography variant="body2" style={{ fontWeight: "200" }}>
                      Regular backups guarantee information recovery in case of
                      unforeseen circumstances.
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>

      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          scrollToSection("shareduled-power-section");
        }}
        sx={{
          alignSelf: "center",
          borderRadius: "8px",
          textTransform: "none",
          backgroundColor: AppColours.MainRed,
          padding: "12px",
          width: { xs: "100%", sm: "80%", md: "60%" },
          marginTop: "5rem",
        }}
      >
        Take Back Your Time: Start Today
      </Button>

      <Button
        color="primary"
        variant="text"
        fullWidth
        onClick={() => {
          scrollToSection("pricing-and-start-section");
        }}
        sx={{
          borderRadius: "8px",
          textTransform: "none",
          textDecoration: "underline",
        }}
      >
        Skip and Get Life Time Access Now
      </Button>
    </Container>
  );
}
