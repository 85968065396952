import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AppColours } from "../../helpers/colors";
import { useNavigate } from "react-router-dom";

const WhyShareduled = () => {
  const navigate = useNavigate();
  const comparisonData = [
    {
      feature: "Complex to set up and hard to navigate.",
      shareduled: "Simple, easy to navigate app.",
    },
    {
      feature: "Expensive. Not suitable for small businesses.",
      shareduled:
        "The best price for owners of barbershops, massage houses, spa, and salons like you.",
    },
    {
      feature: "Limited customization options for branding.",
      shareduled:
        "You'll make your clients 'feel at home' even before coming to you.",
    },
    {
      feature: "Secured with lengthy, easy-to-forget seed phrases.",
      shareduled:
        "Secured with 256-bit encryption, secure cloud storage, and regular backups.",
    },
  ];

  return (
    <Container>
      <div>
        <Typography
          variant="h4"
          component="h1"
          align="center"
          sx={{ color: "#550066", marginBottom: "24px" }}
        >
          Why Should You Choose Shareduled Over Other Appointment Booking Apps?
        </Typography>

        <TableContainer
          component={Paper}
          sx={{
            margin: "0 auto",
            marginTop: 4,
            boxShadow: "none",
            padding: "3rem",
          }}
        >
          <Table sx={{ borderCollapse: "collapse" }}>
            {/* Table Header */}
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell sx={{ fontSize: "26px", fontWeight: "bold" }}>
                  Other Appointment Booking Apps
                </TableCell>
                <TableCell sx={{ fontSize: "26px", fontWeight: "bold" }}>
                  Shareduled
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {comparisonData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ textAlign: "center" }}>❌</TableCell>
                  <TableCell>{row.feature}</TableCell>
                  <TableCell>{row.shareduled}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>✅</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          marginTop: "48px",
          width: "100%",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          align="center"
          sx={{ color: "#550066" }}
        >
          Now, Will You Allow Your Clients To Go To A Competitor?
        </Typography>
        <Typography>Are you comfortable with losing clients?</Typography>
        <Typography>
          Are you comfortable with watching your sales dry up?
        </Typography>
        <Typography>
          Are you okay with disappointing clients and harming your reputation?
        </Typography>
        <Typography>
          Or do you want to take FULL control of your business?
        </Typography>
        <Typography>If you want something different...</Typography>
        <Typography>If you want a smooth scheduling experience...</Typography>
        <Typography>
          If you want to retain more clients and skyrocket your business...
        </Typography>
        <Typography>
          You're going to have to do something different today!
        </Typography>
        <Typography>Make a new choice...</Typography>
        <Typography>
          And Pursue your new outcome by taking this free ROI Test now.
        </Typography>

        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            navigate("/welcome");
          }}
          sx={{
            alignSelf: "center",
            borderRadius: "8px",
            textTransform: "none",
            backgroundColor: AppColours.MainRed,
            padding: "12px",
            width: "100%",
          }}
        >
          Discover My Profit Potential - Analyze My ROI
        </Button>
      </div>
    </Container>
  );
};

export default WhyShareduled;
