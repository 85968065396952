import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { AppColours } from "../../helpers/colors";
import calender from "../../assets/new-landing/Frame 33.png";
import realTime from "../../assets/new-landing/Frame 209.png";
import scheduling from "../../assets/new-landing/Frame 33 (1).png";
import reminder from "../../assets/new-landing/Frame 33 (2).png";
import workflows from "../../assets/new-landing/Frame 33 (3).png";

const ServicesForUsers = () => {
  return (
    <Box component="section">
      <Grid
        container
        spacing={5}
        marginTop={5}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              width: "100%",
              boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
              borderRadius: "20%",
              padding: "30px 0 0 0",
            }}
          >
            <CardActionArea sx={{ display: "flex", flexDirection: "column" }}>
              <CardMedia
                component="img"
                sx={{
                  width: "8rem",
                }}
                src={calender}
                alt="Personalized calender icon"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  sx={{
                    lineHeight: "26px",
                    textAlign: "center",
                    fontWeight: "300",
                    color: AppColours.MainBlack,
                    pb: { xs: 2, sm: 1, md: 1 },
                  }}
                >
                  Personalized calendars and scheduling
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              width: "100%",
              boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
              borderRadius: "20%",
              padding: "30px 0 0 0",
            }}
          >
            <CardActionArea sx={{ display: "flex", flexDirection: "column" }}>
              <CardMedia
                component="img"
                sx={{
                  objectFit: "cover",
                  width: "8rem",
                }}
                image={realTime}
                alt="Real-time availability icon"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  sx={{
                    lineHeight: "26px",
                    textAlign: "center",
                    fontWeight: "300",
                    color: AppColours.MainBlack,
                    pb: { xs: 2, sm: 1, md: 1 },
                  }}
                >
                  Real-time availability checking
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              width: "100%",
              boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
              borderRadius: "20%",
              padding: "30px 0 0 0",
            }}
            elevation={2}
          >
            <CardActionArea sx={{ display: "flex", flexDirection: "column" }}>
              <CardMedia
                component="img"
                sx={{ width: "8rem" }}
                image={scheduling}
                alt="personalized scheduling image"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  sx={{
                    lineHeight: "26px",
                    textAlign: "center",
                    fontWeight: "300",
                    color: AppColours.MainBlack,
                    pb: { xs: 2, sm: 1, md: 1 },
                  }}
                >
                  Personalized calendars and scheduling
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              width: "100%",
              boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
              borderRadius: "20%",
              padding: "30px 0 0 0",
            }}
            elevation={2}
          >
            <CardActionArea sx={{ display: "flex", flexDirection: "column" }}>
              <CardMedia
                component="img"
                sx={{ width: "8rem" }}
                image={reminder}
                alt="Automated Reminder image"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  sx={{
                    lineHeight: "26px",
                    textAlign: "center",
                    fontWeight: "300",
                    color: AppColours.MainBlack,
                    pb: { xs: 2, sm: 1, md: 1 },
                  }}
                >
                  Turn your feedback to features that matter
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              width: "100%",
              boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
              borderRadius: "20%",
              padding: "30px 0 0 0",
            }}
            elevation={2}
          >
            <CardActionArea sx={{ display: "flex", flexDirection: "column" }}>
              <CardMedia
                component="img"
                sx={{ width: "8rem" }}
                image={workflows}
                alt="customizable workflow icon"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  sx={{
                    lineHeight: "26px",
                    textAlign: "center",
                    fontWeight: "300",
                    color: AppColours.MainBlack,
                    pb: { xs: 2, sm: 1, md: 1 },
                  }}
                >
                  Customizable workflows and permissions
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServicesForUsers;
