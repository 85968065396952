import { Box, Container, Divider, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import articles from "../../assets/testBlogData.json";

const ArticleDetails = () => {
  const { title } = useParams<{ title: string }>();

  // Find the article by title
  const article = title
    ? articles.find((art) => art.title === decodeURIComponent(title))
    : undefined;

  if (!article) {
    return (
      <Container>
        <Typography variant="h4" color="error" align="center">
          Article not found!
        </Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ paddingY: 4 }}>
      {/* Article Title */}
      <Typography variant="h3" gutterBottom>
        {article.title}
      </Typography>

      {/* Metadata */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {article.date}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {article.readTime} read
        </Typography>
      </Box>

      <Divider sx={{ marginBottom: 4 }} />

      {/* Article Content */}
      <Box sx={{ typography: "body1", lineHeight: 1.8 }}>
        {article.description.split("\n").map((paragraph, index) => (
          <Typography key={index} paragraph>
            {paragraph}
          </Typography>
        ))}
      </Box>
    </Container>
  );
};

export default ArticleDetails;
