import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { AppColours } from "../../helpers/colors";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PaymentsIcon from "@mui/icons-material/Payments";

import {
  Appearance,
  StripeElementsOptions,
  loadStripe,
} from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { createPaymentIntentForLifeTimeAccess } from "../../services/apis/stripe.service";
import {
  MarketingUserDto,
  PaypalCaptureOrderRespDataDto,
} from "../../sdk/open-api";
import StripeCheckoutForm from "./StripeCheckoutForm";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import {
  captureOrderOnApproval,
  createOrder,
} from "../../services/apis/paypal.service";
import { NavigateFunction, useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { daysRemainingForLifeTimeAccess } from "../../helpers/miscellaneous";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY!,
);

const PaypalCustomButton = (props: {
  customer: MarketingUserDto;
  createOrder: (data: MarketingUserDto) => Promise<string>;
  captureOrder: (
    orderID: string,
  ) => Promise<PaypalCaptureOrderRespDataDto | undefined>;
  navigate: NavigateFunction;
}) => {
  const { customer, createOrder, captureOrder, navigate } = props;
  const [{ isPending }] = usePayPalScriptReducer();

  return (
    <React.Fragment>
      {isPending && (
        <CircularProgress
          id="paypal-spinner"
          color="primary"
          sx={{ alignSelf: "center", mt: { xs: 3, sm: 4 } }}
        />
      )}
      {
        <PayPalButtons
          style={{ layout: "horizontal" }}
          createOrder={async (_, __) => {
            return createOrder(customer);
          }}
          onApprove={async (data) => {
            try {
              const approvedData = await captureOrder(data.orderID);

              if (approvedData) {
                navigate("/payment-success");
              } else {
                console.error("Could not approve payment with data ", data);
              }
            } catch (e) {
              console.error("Problem approving payment with data ", data, e);
            }
          }}
          onError={(err) => console.error("PaypalError oCcurred: ", err)}
        />
      }
    </React.Fragment>
  );
};

export default function LeadMagnetPay(props: {
  user: MarketingUserDto | null;
  existing: boolean;
}) {
  const { user, existing } = props;
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState<string>("stripe");
  const [clientSecret, setClientSecret] = useState("");

  const [currency, setCurrency] = useState<"CAD" | "USD">(
    user?.country?.toLowerCase() === "canada" ? "CAD" : "USD",
  );

  const [PAYPAL_INITIAL_OPTIONS, setPaypalInitialOptions] = useState<{
    clientId: string;
    currency: string;
    intent: string;
  } | null>(null);

  const [amount, setAmount] = useState<{
    orderCost: number;
    hst?: number;
  }>({
    orderCost: 500,
  });

  const [daysRemaining, setDaysRemaining] = useState(
    daysRemainingForLifeTimeAccess(),
  );

  const handleChange =
    (accordionName: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? accordionName : "none");
    };

  useEffect(() => {
    const interval = setInterval(
      () => setDaysRemaining(daysRemainingForLifeTimeAccess()),
      1800000, // Update days remaining every 30 minutes
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    async function createClientSecret() {
      if (!user) return;

      const apiResp = await createPaymentIntentForLifeTimeAccess(
        user as unknown as MarketingUserDto,
      );

      if (!apiResp.success || !apiResp.clientSecret) {
        console.warn("CCS ERROR: ", apiResp);
        return;
      }

      localStorage.setItem("user_data", JSON.stringify({ user, existing }));

      setClientSecret(apiResp.clientSecret);
    }

    createClientSecret();
  }, [user, existing]);

  useEffect(() => {
    function setCurrencyAndOptions() {
      if (!user) return;

      const isCanada = user.country.toLowerCase() === "canada";
      const curr = isCanada ? "CAD" : "USD";
      setCurrency(curr);

      setAmount((prev) => {
        return { ...prev, ...(isCanada && { hst: 65 }) };
      });

      setPaypalInitialOptions({
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID!,
        currency: curr,
        intent: "capture",
      });
    }

    setCurrencyAndOptions();
  }, [user]);

  const appearance: Appearance = {
    theme: "stripe",
  };
  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  };

  return (
    <Container
      maxWidth="lg"
      id="lead-magnet-pay-section"
      sx={{
        minHeight: "inherit",
        justifyContent: "center",
        alignItems: "center",
        pt: { xs: 14, sm: 18 },
        pb: { xs: 6, sm: 6 },
      }}
    >
      {user && (
        <Grid
          container
          spacing={{ xs: 2, sm: 8, md: 10 }}
          sx={{
            justifyContent: "center",
            alignItems: "flex-start",
            minHeight: "inherit",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              minHeight: "inherit",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack
              spacing={{ xs: 2, sm: 3 }}
              useFlexGap
              sx={{
                height: "inherit",
                width: { xs: "100%", sm: "100%" },
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                variant="h4"
                component={"div"}
                sx={{
                  flexWrap: "wrap",
                  textAlign: "left",
                  fontWeight: "600",
                  color: AppColours.MainBlack,
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    display: "inline",
                    flexWrap: "wrap",
                    textAlign: "left",
                    fontWeight: "600",
                    color: (theme) => theme?.palette?.primary.main,
                  }}
                >
                  {user?.first_name ? `${user?.first_name},` : ""}
                </Typography>
                &nbsp; You’re Just One Step Away.
              </Typography>

              <Box sx={{ width: "inherit" }}>
                <Typography
                  variant="caption"
                  color="primary"
                  sx={{ fontWeight: "500" }}
                >
                  Order Details
                </Typography>
                <Box
                  sx={{
                    mt: { xs: 1, sm: 2 },
                    pl: { xs: 2, sm: 4 },
                    width: "inherit",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "300",
                    }}
                  >
                    Life Time Access Cost
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "500",
                    }}
                  >
                    {`${currency === "CAD" ? "CA$" : "US$"} ${
                      amount.orderCost
                    }`}
                  </Typography>
                </Box>
                {user && user.country.toLowerCase() === "canada" && (
                  <Box
                    sx={{
                      mt: { xs: 1, sm: 1 },
                      pl: { xs: 2, sm: 4 },
                      width: "inherit",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "300",
                      }}
                    >
                      HST
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "500",
                      }}
                    >
                      {`CA$ ${amount.hst}`}
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  pl: { xs: 2, sm: 4 },
                  width: "inherit",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  TOTAL
                </Typography>
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{
                    fontWeight: "600",
                  }}
                >
                  {`${currency === "CAD" ? "CA$" : "US$"} ${
                    amount.orderCost + (amount.hst ? amount.hst : 0)
                  }`}
                </Typography>
              </Box>

              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  display: "flex",
                  flexWrap: "wrap",
                  textAlign: "left",
                }}
              >
                Here's a summary of what you get again:
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontWeight: "600",
                  display: "flex",
                  flexWrap: "wrap",
                  textAlign: "left",
                }}
              >
                What You Get:
              </Typography>

              <List sx={{}}>
                {[
                  "Lifetime Access|A one-time payment of $500 grants you lifetime access to Shareduled – no subscription fees, no surprises. Pay once, use forever",
                  "Zero Subscription Fees|Say goodbye to recurring costs and enjoy the simplicity of a one-time investment. Save hundreds annually",
                  "50% Off Transaction Fees|Start saving money immediately on every transaction processed through Shareduled. More savings with every booking",
                  "Our Promise|Try Shareduled risk-free for a year. Not satisfied? Get a full refund, plus an extra month on us. Risk-free trial for one year with an extra month free if you decide it's not for you",
                ].map((x, index) => {
                  const [header, body] = x.split("|");
                  return (
                    <ListItem disablePadding key={index.toString()}>
                      <ListItemIcon>
                        <CheckCircleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "300",
                            textAlign: "left",
                            color: (theme) => theme?.palette?.primary?.main,
                          }}
                        >
                          <strong>
                            <em>{header}</em>
                          </strong>
                          : &nbsp; {body}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </List>

              <Typography
                variant="body1"
                sx={{
                  fontWeight: "600",
                  display: "flex",
                  flexWrap: "wrap",
                  textAlign: "left",
                }}
              >
                Exclusive Early Adopter Benefits:
              </Typography>

              <List sx={{}}>
                {[
                  "One-Time Payment of $500|Covers all future updates and premium features",
                  "Limited to First 200 Users|Secure your spot before this offer expires",
                  "Priority Feedback|Be a key contributor to key improvements and shape the future of Shareduled with your feedback.",
                ].map((x, index) => {
                  const [header, body] = x.split("|");
                  return (
                    <ListItem disablePadding key={index.toString()}>
                      <ListItemIcon>
                        <CheckCircleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "300",
                            textAlign: "left",
                            color: (theme) => theme?.palette?.primary?.main,
                          }}
                        >
                          <strong>
                            <em>{header}</em>
                          </strong>
                          : &nbsp; {body}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </List>

              <Typography
                variant="body1"
                sx={{
                  fontWeight: "400",
                  display: "flex",
                  flexWrap: "wrap",
                  textAlign: "left",
                  color: (theme) => theme?.palette?.primary?.main,
                }}
              >
                Join the Shareduled Family
              </Typography>
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              minHeight: "inherit",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paper
              variant="elevation"
              elevation={0}
              sx={{
                minHeight: "inherit",
                width: "inherit",
                borderRadius: 12,
              }}
            >
              <Stack
                spacing={{ xs: 2, sm: 2 }}
                useFlexGap
                sx={{
                  height: "inherit",
                  width: { xs: "100%", sm: "100%" },
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  pt: { xs: 2, sm: 3 },
                  pb: { xs: 3, sm: 3 },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "300",
                    display: "flex",
                    flexWrap: "wrap",
                    textAlign: "left",
                  }}
                >
                  This exclusive lifetime access offer expires in&nbsp;{" "}
                  <strong>
                    <em>
                      {" "}
                      {`${
                        daysRemaining && daysRemaining > 0 ? daysRemaining : 0
                      }` ?? ""}{" "}
                      days
                    </em>
                  </strong>
                  &nbsp;on&nbsp;
                  <strong>
                    <em>October 26, 2024</em>
                  </strong>
                  &nbsp; at&nbsp;
                  <strong>
                    <em>11:59 PM EST.</em>
                  </strong>
                </Typography>

                <Typography
                  variant="h5"
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    textAlign: "left",
                    fontWeight: "600",
                    color: (theme) => theme?.palette?.primary.main,
                  }}
                >
                  Payment Method
                </Typography>

                <React.Fragment>
                  <Accordion
                    expanded={expanded === "stripe"}
                    onChange={handleChange("stripe")}
                    sx={{
                      width: "inherit",
                      mb: { xs: 1, sm: 2, md: 2 },
                      border: "none",
                      borderRadius: 2,
                      backgroundColor: AppColours.LightPurple,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ width: "inherit" }}
                    >
                      <Box
                        sx={{
                          width: "inherit",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <CreditCardIcon color="primary" fontSize="small" />
                        <Typography
                          variant="body2"
                          color="primary"
                          sx={{
                            paddingLeft: "8px",
                            display: "flex",
                            flexWrap: "wrap",
                            textAlign: "left",
                            fontWeight: "400",
                          }}
                        >
                          Pay With Stripe
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ width: "inherit" }}>
                      <Box
                        sx={{
                          width: "inherit",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "center",
                          alignSelf: "center",
                        }}
                      >
                        {clientSecret && (
                          <Elements options={options} stripe={stripePromise}>
                            <StripeCheckoutForm />
                          </Elements>
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "paypal"}
                    onChange={handleChange("paypal")}
                    sx={{
                      width: "inherit",
                      mb: { xs: 1, sm: 2, md: 2 },
                      border: "none",
                      borderRadius: 2,
                      backgroundColor: AppColours.LightPurple,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ width: "inherit" }}
                    >
                      <Box
                        sx={{
                          width: "inherit",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <PaymentsIcon color="primary" fontSize="small" />
                        <Typography
                          variant="body2"
                          color="primary"
                          sx={{
                            paddingLeft: "8px",
                            display: "flex",
                            flexWrap: "wrap",
                            textAlign: "left",
                            fontWeight: "400",
                          }}
                        >
                          Pay With Paypal
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ width: "100%" }}>
                      {PAYPAL_INITIAL_OPTIONS && (
                        <PayPalScriptProvider options={PAYPAL_INITIAL_OPTIONS}>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "center",
                              alignSelf: "center",
                            }}
                          ></Box>
                          <PaypalCustomButton
                            customer={user! as unknown as MarketingUserDto}
                            createOrder={createOrder}
                            captureOrder={captureOrderOnApproval}
                            navigate={navigate}
                          />
                        </PayPalScriptProvider>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </React.Fragment>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
