import { Box, Button, Container, Typography } from "@mui/material";
import testHeroImage from "../../assets/blog/test-blog-hero.png";

const BlogHero = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: "100vh",
        backgroundImage: `url(${testHeroImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "white",
        position: "relative",
        padding: 4,
      }}
    >
      {/* Overlay to darken background */}
      <Box
      // sx={{
      //   position: 'absolute',
      //   top: 0,
      //   left: 0,
      //   width: '100%',
      //   height: '100%',
      //   bgcolor: 'rgba(0, 0, 0, 0.6)',
      //   zIndex: 1,
      // }}
      />

      <Container sx={{ zIndex: 2 }}>
        <Typography variant="h2" component="h1" gutterBottom color="#550066">
          Welcome to Shareduled
        </Typography>
        <Typography variant="h5" component="p" gutterBottom>
          The World's First App Ever Designed For Paid Appointment Booking in
          Real-Time.
        </Typography>
        <Button
          variant="contained"
          size="large"
          sx={{ mt: 2, backgroundColor: "#550066" }}
          onClick={() => alert("CTA Clicked!")}
        >
          Get Started
        </Button>
      </Container>
    </Box>
  );
};

export default BlogHero;
