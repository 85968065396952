import { Box, Container, Grid, Card, Typography } from "@mui/material";
import { ReactNode, FC } from "react";
import { AppColours } from "../../helpers/colors";
import {
  MyLocation as MyLocationIcon,
  NotificationsActive as NotificationsActiveIcon,
  Tune as TuneIcon,
  DateRange as DateRangeIcon,
  LocationSearching as LocationSearchingIcon,
  Timer as TimerIcon,
  PublishedWithChanges as PublishedWithChangesIcon,
  Google as GoogleIcon,
  Paid as PaidIcon,
  CircleNotifications as CircleNotificationsIcon,
} from "@mui/icons-material";

type IBFData = {
  header: string;
  subHeader: string;
  text1: string;
  text2: string;
  text3?: string; // Optional new field
};

const data: IBFData[] = [
  {
    header: "Real-Time Booking System",
    subHeader: "Why Care?",
    text1:
      "Eliminate the missed opportunities with our real-time booking system.",
    text2:
      "Ensure your clients can book their appointments when it's most convenient for them (and for you), leading to increased client satisfaction and repeat business.",
  },
  {
    header: "Live Availability Notifications",
    subHeader: "Are you too busy to pick the phone?",
    text1: "Keep your clients informed and engaged with live updates instead.",
    text2:
      "This proactive communication can reduce no-shows and last-minute cancellations, optimizing your schedule and revenue.",
  },
  {
    header: "Custom Branding Options",
    subHeader: `Make your client feel "at home" by...`,
    text1:
      "Creating a seamless brand experience with our customization options.",
    text2:
      "Consistent branding across your booking platform reinforces your professional image...",
    text3:
      "And can Increase client trust and loyalty (which brings them back for more)",
  },
  {
    header: "Integrated Walk-In and Online Booking",
    subHeader: "Why Care?",
    text1:
      "Cater to all client preferences by supporting both walk-ins and online bookings.",
    text2:
      "This flexibility can expand your client base quickly and fill your schedule more effectively.",
  },
  {
    header: "Intelligent Location Recommendations",
    subHeader: "Why Care?",
    text1: `This basically shines the "spotlight" on you for your clients to clearly see.`,
    text2:
      "Our location-based recommendations can drive traffic to your business and increase visibility in a competitive market.",
  },
  {
    header: "24/7 Booking Accessibility",
    subHeader: "Why Care?",
    text1: "Never miss a booking opportunity with 24/7 accessibility.",
    text2:
      "This convenience can significantly enhance client satisfaction and set you apart from competitors who don't offer round-the-clock booking.",
  },
  {
    header: "Seamless Cancellation and Rescheduling",
    subHeader: "Why Care?",
    text1: "Handle changes with ease, minimizing disruption to your business.",
    text2:
      "Our seamless process for cancellations and reschedules keeps your operations smooth and clients happy.",
  },
  {
    header: "Google My Business Integration",
    subHeader: "Why Care?",
    text1: "Leverage the power of Google for greater reach.",
    text2:
      "Integrating with Google My Business can improve your online presence and attract more clients through search visibility.",
  },
  {
    header: "Automated Payment Processing",
    subHeader: "Why Care?",
    text1:
      "You don’t need to waste money on 2 or more softwares because of glitchy payment processes.",
    text2:
      "Simplify the payment process, reduce manual work, and get paid faster with our secure online payments.",
  },
  {
    header: "Automated Confirmations & Reminders",
    subHeader: "Why Care?",
    text1:
      "Finally, you will drastically reduce no-shows, improve client communication...",
    text2: "And free up staff time from manual confirmations.",
  },
];

const icons = [
  <MyLocationIcon fontSize="large" color="primary" />,
  <NotificationsActiveIcon fontSize="large" color="primary" />,
  <TuneIcon fontSize="large" color="primary" />,
  <DateRangeIcon fontSize="large" color="primary" />,
  <LocationSearchingIcon fontSize="large" color="primary" />,
  <TimerIcon fontSize="large" color="primary" />,
  <PublishedWithChangesIcon fontSize="large" color="primary" />,
  <GoogleIcon fontSize="large" color="primary" />,
  <PaidIcon fontSize="large" color="primary" />,
  <CircleNotificationsIcon fontSize="large" color="primary" />,
];

const BusinessFeaturesInternal: FC<IBFData & { icon: ReactNode }> = ({
  header,
  subHeader,
  text1,
  text2,
  text3,
  icon,
}) => (
  <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "center" }}>
    <Card
      elevation={0}
      sx={{
        borderRadius: 5,
        display: "flex",
        flexDirection: "row",
        p: { xs: 3, sm: 4, md: 4 },
        alignItems: "flex-start",
      }}
    >
      {icon}
      <Box sx={{ pl: { xs: 2, sm: 3, md: 4 } }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: "600", color: AppColours.MainBlack }}
        >
          {header}
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            fontWeight: "600",
            color: AppColours.MainRed,
            fontStyle: "italic",
          }}
        >
          {subHeader}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontWeight: "300",
            color: AppColours.MainBlack,
            pb: { xs: 1, sm: 2, md: 3 },
          }}
        >
          {text1}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontWeight: "300",
            color: AppColours.MainBlack,
            pb: { xs: 2, sm: 3, md: 4 },
          }}
        >
          {text2}
        </Typography>
        {text3 && (
          <Typography
            variant="body2"
            sx={{
              fontWeight: "300",
              color: AppColours.MainBlack,
              pb: { xs: 2, sm: 3, md: 4 },
            }}
          >
            {text3}
          </Typography>
        )}
      </Box>
    </Card>
  </Grid>
);

const BusinessFeatures: FC = () => (
  <Container
    maxWidth="lg"
    id="business-features-section"
    sx={{
      marginTop: "12rem",
      justifyContent: "center",
      alignItems: "center",
      pt: { xs: 4, sm: 6 },
      pb: { xs: 8, sm: 10 },
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        pb: { xs: 6, sm: 8 },
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontWeight: "600",
          color: (theme) => theme.palette.primary.main,
          flexWrap: "wrap",
          textAlign: "center",
        }}
      >
        Features That Skyrocket Your Business
      </Typography>
    </Box>
    <Grid
      container
      spacing={{ xs: 5, sm: 6, md: 7 }}
      sx={{ justifyContent: "center", alignItems: "flex-start" }}
    >
      {data.map((feature, index) => (
        <BusinessFeaturesInternal
          key={index}
          {...feature}
          icon={icons[index % icons.length]}
        />
      ))}
    </Grid>
  </Container>
);

export default BusinessFeatures;
