import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import articles from "../../assets/testBlogData.json";
import BlogCard from "../blog/BlogCard";

const HomeBlogCard = () => {
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 4,
          marginTop: "12rem",
        }}
      >
        <Typography variant="h4" component="h2" sx={{ color: "#550066" }}>
          News and Updates
        </Typography>

        <Link to={`/blog`}>
          <Button
            sx={{
              backgroundColor: "#f9e7fc",
              borderRadius: "20px",
              display: { xs: "none", sm: "block" },
            }}
          >
            {" "}
            View all
          </Button>
        </Link>
      </Box>

      <Grid container spacing={4}>
        {articles.slice(0, 4).map((article, index) => (
          <Grid item xs={12} sm={6} md={3}>
            <BlogCard
              key={index}
              title={article.title}
              description={article.description}
              readTime={article.readTime}
              date={article.date}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default HomeBlogCard;
