import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";
import { List, ListItem, ListItemText, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import {
  APPLICATION_COST,
  profitMarginCalculator,
  projectedCashflow,
  returnOnInvestment,
} from "../helpers/formulae";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ROIData } from "./screens/ROICalculatorScreen";
import { MarketingUserDto } from "../sdk/open-api";
import KeyIcon from "@mui/icons-material/Key";

function getSummaryList(
  currentProfitMargin: number,
  projectedProfitMargin: number,
  applicationCost: number,
  revenuePercentage: number,
  returnOnInvestment: number,
) {
  return [
    `Your current profit margin is ${currentProfitMargin}%. This means that you make ${currentProfitMargin} dollar of profit for every 1000 dollar of revenue.`,
    `Your projected profit margin after using our application is ${projectedProfitMargin}%. This means that you can make ${projectedProfitMargin} dollar of profit for every 1000 dollar of revenue.`,
    `Your application cost is ${applicationCost} dollar for life! (per month). This is based on our pricing model of a limited-time lifetime access for the beta group (${revenuePercentage} dollar per booking or ${revenuePercentage}% of revenue).`,
    `Your return on investment (ROI) after using our application is ${returnOnInvestment}%. This means that for every 1000 dollar you spend on our application, you can get back ${returnOnInvestment} dollar of profit.`,
  ];
}

export default function Review(
  props: ROIData & {
    user: MarketingUserDto | null;
  },
) {
  const theme = useTheme();

  const {
    averageMonthlyRevenue,
    averageMonthlyExpenses,
    averageMonthlyMarketingSpend,
    averageMonthlyBookings,
    averageMonthlyNoShows,
    user,
  } = props;

  const [profitMargin, setProfitMargin] = useState<{
    current: number;
    projected: number;
  }>({
    current: 0,
    projected: 0,
  });
  const [roi, setROI] = useState<{
    current: number;
    projected: number;
  }>({
    current: 0,
    projected: 0,
  });
  const [applicationCost] = useState(APPLICATION_COST);
  const [projectedResults, setProjectedResults] = useState<{
    revenue: number;
    expenses: number;
    monthlyMarketingSpend: number;
    monthlyBookings: number;
    monthlyNoShows: number;
  }>({
    revenue: 0,
    expenses: 0,
    monthlyMarketingSpend: 0,
    monthlyBookings: 0,
    monthlyNoShows: 0,
  });

  useEffect(() => {
    const starter = () => {
      const projected = projectedCashflow({
        revenue: averageMonthlyRevenue ?? 0,
        expenses: averageMonthlyExpenses ?? 0,
        monthlyMarketingSpend: averageMonthlyMarketingSpend ?? 0,
        monthlyBookings: averageMonthlyBookings ?? 0,
        monthlyNoShows: averageMonthlyNoShows ?? 0,
      });

      const currentProfitMargin = profitMarginCalculator(
        averageMonthlyRevenue ?? 0,
        averageMonthlyExpenses ?? 0,
      );
      const projectedProfitMargin = profitMarginCalculator(
        projected.revenue,
        projected.expenses,
      );
      const projectedROI = returnOnInvestment(
        projectedProfitMargin,
        currentProfitMargin,
        applicationCost,
      );

      setProjectedResults({
        revenue: Number(projected.revenue.toFixed(2)),
        expenses: Number(projected.expenses.toFixed(2)),
        monthlyMarketingSpend: Number(
          projected.monthlyMarketingSpend.toFixed(2),
        ),
        monthlyBookings: Number(projected.monthlyBookings.toFixed(0)),
        monthlyNoShows: Number(projected.monthlyNoShows.toFixed(0)),
      });

      setProfitMargin({
        current: Number(currentProfitMargin.toFixed(2)),
        projected: Number(projectedProfitMargin.toFixed(2)),
      });

      setROI({
        current: Number((projectedROI * 0.75).toFixed(2)), // Formula to calculate current RoI will be used
        projected: Number(projectedROI.toFixed(2)),
      });
    };

    starter();
  }, [
    applicationCost,
    averageMonthlyBookings,
    averageMonthlyExpenses,
    averageMonthlyMarketingSpend,
    averageMonthlyNoShows,
    averageMonthlyRevenue,
  ]);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {`You Made It ${
          user?.first_name ? user?.first_name : ""
        }. Give Yourself a Hug.`}
      </Typography>
      <Typography
        variant="body2"
        component={"div"}
        gutterBottom
        sx={{ fontWeight: "300" }}
      >
        See how Shareduled will help you in your business, take back your time,
        grow your business, increase revenue and focus on what matters.
        <br />
        <br />
        <Typography
          variant="body2"
          sx={{ display: "inline", fontWeight: "500" }}
        >
          Check it below and look out for a special GIFT just for you. We won't
          want you to miss out on it.
        </Typography>
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          paddingRight: "20px",
          mb: { xs: 3, sm: 4 },
        }}
      >
        <Grid item xs={12} sm={6}>
          <BarChart
            xAxis={[
              {
                id: "profitmarginbar",
                data: ["Before Shareduled", "After Shareduled"],
                scaleType: "band",
              },
            ]}
            series={[
              {
                data: [profitMargin.current, profitMargin.projected],
              },
            ]}
            width={400}
            height={300}
            yAxis={[
              {
                label: "Profit Margin (%)",
              },
            ]}
            sx={{
              [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: "translate(-10px, 0)",
              },
            }}
            colors={[theme.palette?.primary?.main]}
          />

          <Typography
            variant="body2"
            sx={{
              alignSelf: "flex-start",
              fontWeight: "300",
              textAlign: "left",
            }}
          >
            You are almost sure to have &nbsp;
            <strong>
              a profit margin increase of&nbsp;
              {(profitMargin.projected - profitMargin.current).toFixed(1)}%
            </strong>
            &nbsp; after switching to Shareduled
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <BarChart
            xAxis={[
              {
                id: "roi",
                data: ["Before Shareduled", "After Shareduled"],
                scaleType: "band",
              },
            ]}
            series={[
              {
                data: [roi.current, roi.projected],
              },
            ]}
            width={400}
            height={300}
            yAxis={[
              {
                label: "ROI (%)",
              },
            ]}
            sx={{
              [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: "translate(-10px, 0)",
              },
            }}
            colors={[theme.palette.info?.main]}
          />

          <Typography
            variant="body2"
            sx={{
              alignSelf: "flex-start",
              fontWeight: "300",
              textAlign: "left",
            }}
          >
            Your ROI will most likely &nbsp;
            <strong>
              increase by a minimum of&nbsp;
              {(roi.projected - roi.current).toFixed(1)}%
            </strong>
            &nbsp; after using Shareduled
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        style={{
          justifyContent: "center",
          alignItems: "center",
          paddingRight: "20px",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Typography
            gutterBottom
            variant="h6"
            style={{
              marginTop: "15px",
              fontSize: "16px",
            }}
          >
            Cash Flow Before Shareduled
          </Typography>
          <PieChart
            series={[
              {
                arcLabel: (item) => `${item.value}`,
                arcLabelMinAngle: 45,
                data: [
                  {
                    label: `AMMS`,
                    value: averageMonthlyMarketingSpend ?? 0,
                    color: theme?.palette?.primary?.main,
                  },
                  {
                    label: "AMR",
                    value: averageMonthlyRevenue ?? 0,
                    color: theme?.palette?.info?.main,
                  },
                  {
                    label: "AME",
                    value: averageMonthlyExpenses ?? 0,
                    color: theme?.palette?.success?.main,
                  },
                ],
                innerRadius: 20,
                outerRadius: 100,
                cornerRadius: 10,
                paddingAngle: 5,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontWeight: "bold",
                fontSize: "10px",
              },
            }}
            width={400}
            height={200}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            gutterBottom
            variant="h6"
            style={{
              marginTop: "20px",
              fontSize: "16px",
            }}
          >
            Cash Flow After Shareduled
          </Typography>
          <PieChart
            series={[
              {
                arcLabel: (item) => `${item.value}`,
                arcLabelMinAngle: 45,
                data: [
                  {
                    label: "AMMS",
                    value: projectedResults.monthlyMarketingSpend,
                    color: theme?.palette?.primary?.main,
                  },
                  {
                    label: "AMR",
                    value: projectedResults.revenue,
                    color: theme?.palette?.info?.main,
                  },
                  {
                    label: "AME",
                    value: projectedResults.expenses,
                    color: theme?.palette?.success?.main,
                  },
                ],
                innerRadius: 20,
                outerRadius: 100,
                cornerRadius: 10,
                paddingAngle: 5,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontWeight: "bold",
                fontSize: "10px",
              },
            }}
            width={400}
            height={200}
          />
        </Grid>
      </Grid>

      <Typography
        variant="body2"
        sx={{
          mt: { xs: 3, sm: 4 },
          alignSelf: "flex-start",
          fontWeight: "300",
          textAlign: "left",
        }}
      >
        Your AMR and AME will likely &nbsp;
        <strong>
          increase by a projected&nbsp; $
          {(
            projectedResults.revenue -
            (averageMonthlyRevenue ? averageMonthlyRevenue : 0)
          ).toFixed(2)}
          &nbsp;and $
          {(
            projectedResults.expenses -
            (averageMonthlyExpenses ? averageMonthlyExpenses : 0)
          ).toFixed(2)}
        </strong>
        &nbsp; respectively
      </Typography>

      <Typography
        variant="body2"
        sx={{
          mt: { xs: 1, sm: 1 },
          alignSelf: "flex-start",
          fontWeight: "300",
          textAlign: "left",
        }}
      >
        Your AMMS will likely &nbsp;
        <strong>
          decrease by a projected&nbsp; $
          {Math.abs(
            projectedResults.monthlyMarketingSpend -
              (averageMonthlyMarketingSpend ? averageMonthlyMarketingSpend : 0),
          ).toFixed(2)}
        </strong>
      </Typography>

      <Grid
        container
        spacing={2}
        style={{
          marginTop: "10px",
          marginBottom: "16px",
          justifyContent: "center",
          alignItems: "center",
          paddingRight: "20px",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Typography
            gutterBottom
            variant="h6"
            style={{
              marginTop: "15px",
              fontSize: "16px",
            }}
          >
            Booking Data Before Shareduled
          </Typography>
          <PieChart
            series={[
              {
                arcLabel: (item) => `${item.value}`,
                arcLabelMinAngle: 45,
                data: [
                  {
                    label: "AMB",
                    value: averageMonthlyBookings ?? 0,
                    color: theme?.palette?.success?.main,
                  },
                  {
                    label: "AMNS",
                    value: averageMonthlyNoShows ?? 0,
                    color: theme?.palette?.error?.main,
                  },
                ],
                innerRadius: 20,
                outerRadius: 100,
                cornerRadius: 10,
                paddingAngle: 5,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontWeight: "bold",
                fontSize: "10px",
              },
            }}
            width={400}
            height={200}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            gutterBottom
            variant="h6"
            style={{
              marginTop: "20px",
              fontSize: "16px",
            }}
          >
            Booking Data After Shareduled
          </Typography>
          <PieChart
            series={[
              {
                arcLabel: (item) => `${item.value}`,
                arcLabelMinAngle: 45,
                data: [
                  {
                    label: "AMB",
                    value: projectedResults.monthlyBookings,
                    color: theme?.palette?.success?.main,
                  },
                  {
                    label: "AMNS",
                    value: projectedResults.monthlyNoShows,
                    color: theme?.palette?.error?.main,
                  },
                ],
                innerRadius: 20,
                outerRadius: 100,
                cornerRadius: 10,
                paddingAngle: 5,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontWeight: "bold",
                fontSize: "10px",
              },
            }}
            width={400}
            height={200}
          />
        </Grid>
      </Grid>

      <Typography
        variant="body2"
        sx={{
          mt: { xs: 3, sm: 4 },
          mb: { xs: 3, sm: 4 },
          alignSelf: "flex-start",
          fontWeight: "300",
          textAlign: "left",
        }}
      >
        What does a &nbsp;
        <strong>
          {(
            ((projectedResults.monthlyBookings - averageMonthlyBookings!) /
              averageMonthlyBookings!) *
            100
          ).toFixed(0)}
          % increase in AMB
        </strong>
        &nbsp; and a &nbsp;
        <strong>
          {Math.abs(
            ((projectedResults.monthlyNoShows - averageMonthlyNoShows!) /
              averageMonthlyNoShows!) *
              100,
          ).toFixed(0)}
          % decrease in no-shows
        </strong>
        &nbsp; mean to your business?
      </Typography>

      <List>
        {getSummaryList(
          profitMargin.current,
          profitMargin.projected,
          applicationCost,
          roi.projected,
          roi.projected,
        ).map((sentence, index) => (
          <ListItem disablePadding key={index.toString()}>
            <ListItemIcon>
              <CheckCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText disableTypography>
              <Typography variant="body2" style={{ fontWeight: "300" }}>
                {sentence}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>

      <List
        sx={{
          alignSelf: { xs: "flex-start", sm: "flex-start" },
        }}
      >
        {[
          { AMR: "Average Monthly Revenue" },
          { AME: "Average Monthly Expenses" },
          { AMMS: "Average Monthly Marketing Spend" },
          { AMB: "Average Monthly Bookings" },
          { AMNS: "Average Monthly No Shows" },
        ].map((sentence, index) => {
          const keyArr = Object.keys(sentence)[0];
          return (
            <ListItem disablePadding key={index.toString()}>
              <ListItemIcon>
                <KeyIcon color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText disableTypography>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "300",
                    fontSize: { xs: "12px", sm: "16px" },
                  }}
                >
                  <strong>{keyArr}</strong>
                  {` = ${(sentence as any)[keyArr]}`}
                </Typography>
              </ListItemText>
            </ListItem>
          );
        })}
      </List>

      <Typography
        variant="body1"
        sx={{
          mt: { xs: 3, sm: 4 },
          alignSelf: "flex-start",
          fontWeight: "400",
          textAlign: "left",
        }}
      >
        You have seen how the future of your business could be better. Now...
      </Typography>
    </React.Fragment>
  );
}
