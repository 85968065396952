import { Box, Button, Paper, Typography } from "@mui/material";
import BlogImage from "../../assets/blog/Blog Image.png";
import { Link } from "react-router-dom";

interface Article {
  title: string;
  description: string;
  date: string;
  readTime: string;
}
const BlogCard = ({ title, description, date, readTime }: Article) => {
  return (
    <Paper>
      <Box>
        <Box
          component="img"
          src={BlogImage}
          alt="green iguana"
          sx={{
            width: "100%",
            height: 250,
            objectFit: "cover",
          }}
        />
      </Box>
      <Box sx={{ padding: "16px" }}>
        <Typography gutterBottom variant="h6" component="h3">
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", marginBottom: "16px" }}
        >
          {description}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {date}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {readTime}
          </Typography>
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <Link
            to={`/blog/${encodeURIComponent(title)}`}
            style={{ textDecoration: "none" }}
          >
            <Button size="small" variant="contained">
              Learn More
            </Button>
          </Link>
        </Box>
      </Box>
    </Paper>
  );
};

export default BlogCard;
