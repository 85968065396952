import { Theme } from "@emotion/react";
import { SxProps, Typography } from "@mui/material";

type IPSTextType = {
  textPlain: string;
  textBold?: string;
  textUnderline?: string;
  style?: SxProps<Theme>;
};

const CustomText = (props: IPSTextType) => {
  const { textPlain, style } = props;

  return (
    <Typography
      variant="body2"
      sx={style ? style : { fontWeight: "300", alignSelf: "flex-start" }}
    >
      {textPlain}
    </Typography>
  );
};

export default CustomText;
