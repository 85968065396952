import * as React from "react";
import LayoutV2 from "../layout/LayoutV2";
import ServiceExcellence from "../landing-page/ServiceExcellence";
import BusinessFeatures from "../landing-page/BusinessFeatures";
import FAQ from "../landing-page/FAQ";
// import ShareduledPower from "../landing-page/ShareduledPower";
import { useEffect, useState } from "react";
import PricingAndStart from "../landing-page/PricingAndStart";
// import ProblemSolutionLead from "../landing-page/ProblemSolutionLead";
import UniqueShareduled from "../landing-page/UniqueShareduled";
import WhyShareduled from "../landing-page/WhyShareduled";
import LeadMagnet from "../landing-page/LeadMagnet";
import ShareduledFirstApp from "../landing-page/ShareduledFirstApp";
import HomeBlogCard from "../landing-page/HomeBlogCard";
// import ShareduledAdvantage from "../landing-page/ShareduledAdvantage";

export default function LifeAccessScreen() {
  const [open, setOpen] = useState(false);

  const [secondsToOpen, setSecondsToOpen] = useState(5);

  // const handleClose = () => {
  //   setOpen(false);
  //   setSecondsToOpen(-1);
  // };

  useEffect(() => {
    localStorage.removeItem("user_data");
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (secondsToOpen === 0 && !open) {
        setOpen(true);
        setSecondsToOpen(-1);
      } else if (secondsToOpen > 0) {
        setSecondsToOpen((prev) => prev - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [secondsToOpen, open]);

  return (
    <LayoutV2
      child={
        <React.Fragment>
          <ShareduledFirstApp />
          {/* <SummarySection /> */}
          {/* <ProblemSolutionLead /> */}
          <UniqueShareduled />
          {/* <Divider /> */}
          {/* <ShareduledAdvantage /> */}
          {/* <SolvingPuzzle /> */}
          {/* <Divider /> */}
          <ServiceExcellence />
          {/* <Divider /> */}
          <BusinessFeatures />
          <WhyShareduled />
          <LeadMagnet />
          <PricingAndStart />
          {/* <Divider /> */}
          <HomeBlogCard />
          <FAQ />
          {/* <ShareduledPower /> */}
          {/* <Divider /> */}
          {/* <MarketingModal open={open} handleClose={handleClose} /> */}
        </React.Fragment>
      }
    />
  );
}
