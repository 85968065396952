import {
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { AppColours } from "../../helpers/colors";
import { useNavigate } from "react-router-dom";

export default function PricingAndStart() {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="lg"
      id="pricing-and-start-section"
      sx={{
        marginTop: "12rem",
        minHeight: "inherit",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        pt: { xs: 4, sm: 6 },
        pb: { xs: 8, sm: 10 },
      }}
    >
      <Box
        sx={{
          width: "inherit",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: (theme) => {
            return {
              [theme.breakpoints.down("sm")]: {
                display: "flex-start",
              },
            };
          },
          pb: { xs: 2, sm: 4 },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            color: (theme) => theme?.palette?.primary?.main,
            display: "flex",
            textAlign: "left",
            alignSelf: (theme) => {
              return {
                [theme.breakpoints.down("sm")]: {
                  display: "flex-start",
                  textAlign: "left",
                },
              };
            },
            flexWrap: "wrap",
          }}
        >
          Already A Fan of Shareduled? Be One of the First to Experience
          Shareduled in it's Full Glory.
        </Typography>
      </Box>

      <Grid
        container
        direction="row"
        spacing={{ xs: 8, sm: 8 }}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "inherit",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            height: "inherit",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            spacing={{ xs: 2, sm: 1 }}
            useFlexGap
            sx={{
              height: "inherit",
              width: { xs: "100%", sm: "100%" },
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              pb: { xs: 2, sm: 4 },
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: "500",
                //   fontStyle: "italic",
                pb: { xs: 2, sm: 2, md: 3 },
                textAlign: "left",
                alignSelf: (theme) => {
                  return {
                    [theme.breakpoints.down("sm")]: {
                      display: "flex-start",
                      textAlign: "left",
                    },
                  };
                },
                flexWrap: "wrap",
              }}
            >
              Don't Miss Out, Skip the Queue and Experience Shareduled to the
              Fullest! Join The waitlist for Your VIP Pass to Lifetime Access
              and Exclusive Benefits.
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: "300",
                pb: { xs: 2, sm: 2, md: 3 },
              }}
            >
              Don't wait in line for regular access - Be an early adopter and
              secure your lifetime Shareduled membership with exclusive benefits
              (before it's too late).
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: "300",
                pb: { xs: 2, sm: 2, md: 3 },
              }}
            >
              You can unlock a lifetime of streamlined scheduling of our
              game-changing scheduling software by skipping the wait and taking
              the next step.
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: "300",
                pb: { xs: 2, sm: 2, md: 3 },
              }}
            >
              You’ve seen how Shareduled can revolutionize your scheduling
              needs. Now, we’re offering our biggest fans - those who love what
              we can do for their business from our contents - the chance to get
              lifetime access. We really want you to be the first to experience
              our exclusive benefits - lifetime access to all our premium
              features. It’s our way of saying thank you.
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: "300",
                pb: { xs: 2, sm: 2, md: 3 },
              }}
            >
              Quick confession: our early adopters get the best perks.
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: "300",
                pb: { xs: 2, sm: 2, md: 3 },
              }}
            >
              You’ve seen what Shareduled can do. Now, imagine having lifetime
              access to all our features.
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: "300",
                pb: { xs: 2, sm: 2, md: 3 },
                mb: { xs: 4, sm: 5, md: 6 },
              }}
            >
              Don't wait to hear about the special deal we have for our biggest
              fans, Secure your spot today.
            </Typography>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            height: "inherit",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              pb: { xs: 6, sm: 0 },
            }}
          >
            <CardMedia
              component="img"
              loading="lazy"
              src="image"
              image="https://shareduled-assets.nyc3.cdn.digitaloceanspaces.com/marketing/Already-a-Fan.png"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                height: { xs: 600, sm: 880 },
                borderRadius: 5,
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          navigate("/life-time-access-form");
        }}
        sx={{
          alignSelf: "center",
          borderRadius: "8px",
          textTransform: "none",
          backgroundColor: AppColours.MainGreen,
          padding: "12px",
          width: "100%",
        }}
      >
        Give Me Priority Access To The Waitlist Now
      </Button>
    </Container>
  );
}
