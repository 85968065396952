import {
  Button,
  Container,
  Typography,
  Box,
  List,
  ListItem,
  Grid,
} from "@mui/material";
import { scrollToSection } from "../../helpers/miscellaneous";
import { AppColours } from "../../helpers/colors";
import smartImage from "../../assets/langing-page/Smart-Scheduling.png";

const LeadMagnet = () => {
  return (
    <Container sx={{ marginTop: "12rem", padding: "24px" }}>
      <section className="flex flex-col">
        <Typography
          variant="h4"
          component="h1"
          align="center"
          sx={{
            color: "#550066",
            marginBottom: "24px",
            fontSize: "36px",
            fontWeight: "bold",
          }}
        >
          See For Yourself: The Real Power of Shareduled... So You Can Project
          Your Profits{" "}
          <span style={{ textDecoration: "underline" }}>With Precision</span>
        </Typography>

        <Grid container spacing={12} marginTop="1rem">
          <Grid item xs={12} sm={6}>
            <Box
              component="img"
              src={smartImage}
              alt="Smart user using shareduled"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="h6"
              component="h2"
              sx={{
                color: "#550066",
                marginBottom: "24px",
                fontSize: "24px",
                fontWeight: "600",
              }}
            >
              Want to see real numbers on the impact Shareduled can make on your
              revenue and efficiency?
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                marginBottom: "24px",
              }}
            >
              <Typography>
                Imagine a future where every minute translates to money well
                earned.
              </Typography>
              <Typography>
                Every business move should be a step towards greater profits and
                our ROI calculator paints a clear picture of this future,
                showing you the direct impact of Shareduled on your finances.
              </Typography>
              <Typography>
                Take control of your earnings today, dive into the numbers and
                start making every second count.
              </Typography>
              <Typography>
                The future is bright, and it's just one click away.
              </Typography>
            </Box>

            <Typography
              variant="h6"
              component="h3"
              sx={{
                color: "#550066",
                marginTop: "48px",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              You will see how you can:
            </Typography>

            <List
              sx={{
                listStyleType: "disc",
                paddingLeft: "24px",
                marginBottom: "24px",
              }}
            >
              <ListItem>
                Make informed decisions about your revenue and profitability.
              </ListItem>
              <ListItem>
                Unlock hidden profit potentials within your business.
              </ListItem>
              <ListItem>
                Assess suitability of Shareduled based on data.
              </ListItem>
              <ListItem>
                Make a confident investment decision for your business.
              </ListItem>
              <ListItem>
                Gain a clear understanding of Shareduled's impact.
              </ListItem>
            </List>
          </Grid>
        </Grid>

        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            scrollToSection("shareduled-power-section");
          }}
          sx={{
            alignSelf: "center",
            borderRadius: "8px",
            textTransform: "none",
            backgroundColor: AppColours.MainRed,
            padding: "12px",
            width: { xs: "100%", sm: "80%", md: "60%" },
            margin: "auto",
          }}
        >
          Take Back Your Time: Start Today
        </Button>

        <Button
          color="primary"
          variant="text"
          fullWidth
          onClick={() => {
            scrollToSection("pricing-and-start-section");
          }}
          sx={{
            borderRadius: "8px",
            textTransform: "none",
            textDecoration: "underline",
            marginTop: "16px",
          }}
        >
          Skip and Get Life Time Access Now
        </Button>
      </section>
    </Container>
  );
};

export default LeadMagnet;
