import React from "react";
import LayoutV2 from "../layout/LayoutV2";
import BlogHero from "../blog/BlogHero";
import BlogCard from "../blog/BlogCardSection";

const Blog = () => {
  return (
    <LayoutV2
      child={
        <React.Fragment>
          <BlogHero />
          <BlogCard />
        </React.Fragment>
      }
    />
  );
};

export default Blog;
