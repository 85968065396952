import { Box, Grid, Stack, Typography } from "@mui/material";
import businessOwner from "../../assets/new-landing/business owner.png";

const ServicesForBusiness = () => {
  return (
    <Box component="section" marginTop={5}>
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: "#550066",
          fontWeight: "bold",
          marginY: "1rem",
          textAlign: "center",
        }}
      >
        Services for Business Owners
      </Typography>

      <Grid container spacing={5}>
        {/* left column */}
        <Grid item md={6}>
          <Box
            component="img"
            src={businessOwner}
            alt="A business owner relaxing"
          />
        </Grid>

        {/* Right column */}
        <Grid item md={6} alignSelf="center">
          <Stack spacing={5}>
            <Box>
              <Typography
                component="h3"
                variant="h5"
                sx={{
                  color: "#550066",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                Scheduling and Booking Management
              </Typography>
              <Typography>
                Shareduled allows businesses to manage scheduling, bookings, and
                appointments efficiently, reducing no-shows and streamlining
                operations
              </Typography>
            </Box>

            <Box>
              <Typography
                component="h3"
                variant="h5"
                sx={{
                  color: "#550066",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                Resource Sharing and Management
              </Typography>
              <Typography>
                Businesses can share and manage resources such as meeting rooms,
                equipment, and vehicles, optimizing utilization and reducing
                costs
              </Typography>
            </Box>

            <Box>
              <Typography
                component="h3"
                variant="h5"
                sx={{
                  color: "#550066",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                Client and Customer Management
              </Typography>
              <Typography>
                Shareduled provides tools for managing client relationships,
                including scheduling, communication, and payment tracking
              </Typography>
            </Box>

            <Box>
              <Typography
                component="h3"
                variant="h5"
                sx={{
                  color: "#550066",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                Team Collaboration and Communication
              </Typography>
              <Typography>
                Shareduled enables teams to collaborate, communicate, and share
                information securely, improving productivity and efficiency
              </Typography>
            </Box>

            <Box>
              <Typography
                component="h3"
                variant="h5"
                sx={{
                  color: "#550066",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                Reporting and Analytics
              </Typography>
              <Typography>
                Shareduled offers reporting and analytics tools to help
                businesses track performance, identify trends, and make
                data-driven decisions
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServicesForBusiness;
