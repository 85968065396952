import React from "react";
import LayoutV2 from "../layout/LayoutV2";
import Hero from "../new-landing/Hero";
import { Container } from "@mui/material";
import ServicesForBusiness from "../new-landing/ServicesForBusiness";
import ServicesForUsers from "../new-landing/ServicesForUsers";
import Features from "../new-landing/Features";
import Questions from "../new-landing/Questions";
import Pricing from "../new-landing/Pricing";

const NewLandingPage = () => {
  return (
    <LayoutV2
      child={
        <React.Fragment>
          <Container
            sx={{
              padding: "3rem",
            }}
          >
            <Hero />
            <ServicesForBusiness />
            <ServicesForUsers />
            <Pricing />
            <Features />
            <Questions />
          </Container>
        </React.Fragment>
      }
    />
  );
};

export default NewLandingPage;
