import React from "react";
import SectionWith2Columns, {
  defaultStackStyle,
} from "../shared/SectionWith2Columns";
import {
  Box,
  Button,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { scrollToSection } from "../../helpers/miscellaneous";
import { AppColours } from "../../helpers/colors";

const LeftChild = (
  <React.Fragment>
    <Stack
      marginTop="5rem"
      spacing={{ xs: 2, sm: 3 }}
      useFlexGap
      sx={{ ...defaultStackStyle }}
    >
      <Typography
        variant="body2"
        sx={{
          flexWrap: "wrap",
          textAlign: "left",
          fontWeight: "300",
          alignSelf: "flex-start",
        }}
      >
        Do you own a busy barbershop, massage center, spa or salon? Sky high
        no-show rates, disappointing clients, and losing customers becomes a
        thing of the past when you…
      </Typography>

      <Typography
        variant="h4"
        sx={{
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        Get&nbsp;
        <Typography
          component="span"
          variant="h4"
          sx={{
            color: (theme) =>
              theme.palette.mode === "light" ? "primary.main" : "primary.light",
            fontWeight: "bolder",
          }}
        >
          “Shareduled”&nbsp;
        </Typography>
        - The World's First App Ever Designed For Booking Appointments&nbsp;
        <Typography
          component="span"
          variant="h4"
          sx={{
            color: (theme) =>
              theme.palette.mode === "light" ? "primary.main" : "primary.light",
            fontWeight: "bolder",
            textDecoration: "underline",
            fontStyle: "italic",
          }}
        >
          In Real-Time
        </Typography>
      </Typography>

      <List
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ListItem disablePadding key="0">
          <ListItemIcon>
            <FiberManualRecordIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography variant="body2" style={{ fontWeight: "300" }}>
              You can now attend to clients{" "}
              <Typography
                variant="body2"
                component="span"
                style={{ fontWeight: "300", textDecoration: "underline" }}
              >
                at your most convenient time…
              </Typography>{" "}
              and have more time to handle other aspects of business.
            </Typography>
          </ListItemText>
        </ListItem>

        <ListItem disablePadding key="1">
          <ListItemIcon>
            <FiberManualRecordIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography variant="body2" style={{ fontWeight: "300" }}>
              You'll never worry about clients ghosting you after booking an
              appointment because of our “locked in” policy (you will LOVE
              this).
            </Typography>
          </ListItemText>
        </ListItem>

        <ListItem disablePadding key="2">
          <ListItemIcon>
            <FiberManualRecordIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography variant="body2" style={{ fontWeight: "300" }}>
              And you'll retain more clients and skyrocket your business in
              record time.
            </Typography>
          </ListItemText>
        </ListItem>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            scrollToSection("shareduled-power-section");
          }}
          sx={{
            alignSelf: "center",
            borderRadius: "8px",
            textTransform: "none",
            backgroundColor: AppColours.MainRed,
            padding: "12px",
            width: { xs: "100%", sm: "80%", md: "60%" },
            // marginTop: "5rem"
          }}
        >
          Take Back Your Time: Start Today
        </Button>

        <Button
          color="primary"
          variant="text"
          fullWidth
          onClick={() => {
            scrollToSection("pricing-and-start-section");
          }}
          sx={{
            borderRadius: "8px",
            textTransform: "none",
            textDecoration: "underline",
          }}
        >
          Skip and Get Life Time Access Now
        </Button>
      </List>
    </Stack>
  </React.Fragment>
);

const RightChild = (
  <React.Fragment>
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        maxHeight: "100vh",
        pt: { xs: 4, sm: 6 },
        pb: { xs: 4, sm: 6 },
      }}
    >
      <CardMedia
        component="img"
        loading="lazy"
        src="image"
        image={
          "https://shareduled-assets.nyc3.cdn.digitaloceanspaces.com/marketing/empower-your-business.png"
        }
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: { xs: 360, sm: 560, md: 560 },
          borderRadius: 5,
        }}
      />
    </Box>
  </React.Fragment>
);

function ShareduledFirstApp() {
  return (
    <SectionWith2Columns
      containerId="shareduled-first-app"
      leftChild={LeftChild}
      rightChild={RightChild}
    />
  );
}

export default ShareduledFirstApp;
