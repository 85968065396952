import { Box, Link, Stack, Typography } from "@mui/material";

const Questions = () => {
  return (
    <Box
      component="section"
      marginTop={8}
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <Stack spacing={4} margin="auto">
        <Typography
          component="h2"
          variant="h4"
          sx={{
            color: "#550066",
            fontWeight: "bold",
            marginY: "1rem",
            textAlign: "center",
          }}
        >
          Have Any Questions For Us?
        </Typography>

        <Typography>
          Do you have any special requests for us? You can send an email
        </Typography>
        <Link
          href="mailto:contact@shareduled.com"
          underline="none"
          textAlign="center"
          sx={{ fontWeight: "bold" }}
        >
          contact@shareduled.com
        </Link>
      </Stack>
    </Box>
  );
};

export default Questions;
