import { Box, Grid, Stack, Typography } from "@mui/material";
import smilingClient from "../../assets/new-landing/smiling client.png";

const Hero = () => {
  return (
    <Grid container spacing={5}>
      {/* Left column */}
      <Grid item md={6} alignSelf="center">
        <Stack spacing={5}>
          <Typography variant="body1" component="p">
            If you want to join the ranks of top performing businesses with ZERO
            scheduling “headaches” then you should…
          </Typography>

          <Typography variant="h4" component="h3">
            Get{" "}
            <Box component="span" sx={{ color: "#550066", fontWeight: "bold" }}>
              “Shareduled”
            </Box>{" "}
            - The World's First App Ever Designed For Paid Appointment Booking
            In{" "}
            <Box
              component="span"
              sx={{
                color: "#550066",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
            >
              Real-Time
            </Box>
          </Typography>

          <Typography
            component="ul"
            sx={{
              listStyle: "disc",
              paddingLeft: 4,
              "& li::marker": {
                color: "#550066", // Change the bullet color
              },
            }}
          >
            <li>
              You can now attend to clients at your most convenient time… and
              have more time to handle other aspects of business
            </li>
            <li>
              You'll never worry about clients ghosting you after booking an
              appointment because of our “locked in” policy (you will LOVE
              this).
            </li>
            <li>
              And you'll retain more clients and skyrocket your business in
              record time
            </li>
          </Typography>
        </Stack>
      </Grid>

      {/* Right column */}
      <Grid item md={6}>
        <Box component="img" src={smilingClient} alt="Happy client smilling" />
      </Grid>
    </Grid>
  );
};

export default Hero;
