import { Container, Grid, SxProps, Theme } from "@mui/material";
import React from "react";

export const defaultStackStyle: SxProps<Theme> = {
  height: "inherit",
  width: { xs: "100%", sm: "100%" },
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  pt: { xs: 4, sm: 6 },
};

type SectionType = {
  containerId: string;
  leftChild: React.ReactNode;
  rightChild: React.ReactNode;
  gridContainerSpacingXS?: number;
  gridContainerSpacingSM?: number;
};

function SectionWith2Columns(props: SectionType) {
  const {
    leftChild,
    rightChild,
    containerId,
    gridContainerSpacingXS,
    gridContainerSpacingSM,
  } = props;

  return (
    <Container
      maxWidth="lg"
      id={containerId}
      sx={{
        minHeight: "inherit",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        spacing={{
          xs: gridContainerSpacingXS ?? 6,
          sm: gridContainerSpacingSM ?? 8,
        }}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "inherit",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            height: "inherit",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {leftChild}
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            height: "inherit",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {rightChild}
        </Grid>
      </Grid>
    </Container>
  );
}

export default SectionWith2Columns;
